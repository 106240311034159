//Do not delete or edit enum value (REWARDS_POP_UP_WIDGET = "RewardsPopUpWidget"),
//                                                           ^^^^^^^^^^^^^^^^^^

export enum REWARDS_WIDGET_NAMES {
    REWARDS_POP_UP_WIDGET = "RewardsPopUpWidget",
    REWARDS_VERIFY_ACCOUNT_MODAL_WIDGET = "RewardsVerifyAccountModalWidget",
    REWARDS_JOIN_NOW_MODAL_WIDGET = "RewardsJoinNowModalWidget",
    REWARDS_WELCOME_BANNER_PAGE_WIDGET = "RewardsWelcomeBannerPageWidget",
    REWARDS_HOW_IT_WORKS_PAGE_WIDGET = "RewardsHowItWorksPageWidget",
    REWARDS_WAYS_TO_EARN_PAGE_WIDGET = "RewardsWaysToEarnPageWidget",
}

export enum REVIEWS_WIDGET_NAMES {
    REVIEWS_DRAWER_WIDGET = "ReviewsDrawerWidget",
    REVIEWS_PRODUCT_PAGE_WIDGET = "ReviewsProductPageWidget",
    PRODUCT_REVIEWS_MINI_SLIDER_WIDGET = "ProductReviewsMiniSliderWidget",
    REVIEWS_PAGE_WIDGET = "ReviewsPageWidget",
    LEAVE_REVIEW_PAGE_WIDGET = "LeaveReviewPageWidget",
    AVERAGE_REVIEWS_WIDGET = "AverageReviewsWidget",
    REVIEWS_SLIDER_WIDGET = "ReviewsSliderWidget",
    LISTING_AVERAGE_REVIEWS_WIDGET = "ListingAverageReviewsWidget",
    REVIEWS_FORM_MODAL_WIDGET = "ReviewsFormModalWidget",
    QUESTIONS_FORM_MODAL_WIDGET = "QuestionsFormModalWidget",
    REVIEWS_GALLERY_MODAL_WIDGET = "ReviewsGalleryModalWidget",
}

export enum WISHLIST_WIDGET_NAMES {
    ADD_TO_WISHLIST_PRODUCT_CARD_WIDGET = "AddToWishlistProductCardWidget",
    ADD_TO_WISHLIST_PRODUCT_PAGE_WIDGET = "AddToWishlistProductPageWidget",
    GO_TO_WISHLIST_PAGE_HEADER_WIDGET = "GoToWishlistPageHeaderWidget",
    WISHLIST_PAGE_WIDGET = "WishlistPageWidget",
    WISHLIST_DRAWER_WIDGET = "WishlistDrawerWidget",
}
