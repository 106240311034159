import { Infer, object, string } from "superstruct";
import { InjectionToken } from "tsyringe";

export const RewardsApplicationConfigSchema = object({
    rewardsPopUpWidgetWrapperPlaceholder: string(),
    rewardsVerifyAccountModalWidgetPlaceholder: string(),
    rewardsJoinNowModalWidgetPlaceholder: string(),
    rewardsWelcomeBannerPageWidgetPlaceholder: string(),
    rewardsHowItWorksPageWidgetPlaceholder: string(),
    rewardsWaysToEarnPageWidgetPlaceholder: string(),
});

export type RewardsApplicationConfigInterface = Infer<
    typeof RewardsApplicationConfigSchema
>;

export const RewardsApplicationConfigToken = Symbol(
    "RewardsApplicationConfigToken"
) as InjectionToken<RewardsApplicationConfigInterface>;
