import "reflect-metadata";

import "./themes/default/default.css";
import "./themes/globals.css";

import {
    IloomCustomizationApplicationConfigInterface,
    IloomCustomizationApplicationConfigToken,
} from "@apps/iloom_customization/IloomCustomizationApplicationConfig";
import { ProductModule } from "@apps/iloom_customization/modules/products/ProductModule";
import {
    InstagramApplicationConfigInterface,
    InstagramApplicationConfigToken,
} from "@apps/instagram/InstagramApplicationConfig";
import { ReviewsApplicationConfigInterface } from "@apps/reviews/ReviewsApplicationConfig";
import { ReviewsApplicationConfigToken } from "@apps/reviews/tokens";
import { RewardsWidgetsPlaceholders } from "@apps/rewards/constants/constants";
import {
    RewardsApplicationConfigInterface,
    RewardsApplicationConfigToken,
} from "@apps/rewards/RewardsApplicationConfig";
import RewardsPopUpWidgetLauncherManager from "@apps/rewards/widgets/rewards_popup_widget/utils/RewardsPopUpWidgetLauncherManager/RewardsPopUpWidgetLauncherManager";
import {
    TikTokApplicationConfigInterface,
    TikTokApplicationConfigToken,
} from "@apps/tiktok/TikTokApplicationConfig";
import {
    WishlistApplicationConfigInterface,
    WishlistApplicationConfigToken,
} from "@apps/wishlist/WishlistApplicationConfig";
import { akitaDevtools } from "@datorama/akita";
import { AnalyticsEventsFactory } from "@lib/AnalyticsEventsCollector/AnalyticsEventsFactory";
import RxJSAnalyticsEventsCollector from "@lib/AnalyticsEventsCollector/RxJSAnalyticsEventsCollector";
import AnalyticsEventsSender from "@lib/AnalyticsEventsSender/AnalyticsEventsSender";
import ApiClient from "@lib/ApiClient";
import ApiClientWithMetrics from "@lib/ApiClient/ApiClientWithMetrics";
import AuthApiClient from "@lib/ApiClient/AuthApiClient";
import BrandingProvider from "@lib/BrandingProvider/BrandingProvider";
import FromShopifyCurrencyCurrencyProvider from "@lib/CurrencyProvider/FromShopifyCurrencyCurrencyProvider";
import CustomerInfoProvider from "@lib/CustomerInfoProvider/CustomerInfoProvider";
import CustomizationManager from "@lib/CustomizationManager/CustomizationManager";
import FetchAndXHRPatcher from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import MoneyFormatter from "@lib/Formatters/MoneyFormatter/MoneyFormatter";
import { GraphQLApiClient } from "@lib/GraphQLApiClient/GraphQLApiClient";
import GrowaveFeaturesProvider from "@lib/GrowaveFeaturesProvider";
import GwCurrency from "@lib/GwCurrency/GwCurrency";
import { GwI18n } from "@lib/GwI18n/GwI18n";
import { I18nApiLoader } from "@lib/GwI18n/loaders/I18nApiLoader/I18nApiLoader";
import HistoryPatcher from "@lib/HistoryPatcher";
import XSSHTMLSanitizer from "@lib/HTMLSanitizer/XSSHTMLSanitizer";
import { LegacyAdapter } from "@lib/LegacyAdapter/LegacyAdapter";
import { SimpleLocalizationInfoProvider } from "@lib/LocalizationInfoProvider/SimpleLocalizationInfoProvider";
import LocationController from "@lib/LocationController/LocationController";
import FakeMoneyExchanger from "@lib/MoneyExchanger/FakeMoneyExchanger";
import LegacyMoneyExchanger from "@lib/MoneyExchanger/LegacyMoneyExchanger";
import ReferrerProvider from "@lib/ReferrerProvider";
import ScrollManager from "@lib/ScrollManager/ScrollManager";
import Timer from "@lib/timer";
import GrowaveTokenManager from "@lib/TokenManager/GrowaveTokenManager";
import ShopifyCartApiService from "@modules/cart/api_services/CartService/ShopifyCartApiService";
import { CartQuery } from "@modules/cart/queries/CartQuery";
import { CartService } from "@modules/cart/services/CartService";
import { CartStore } from "@modules/cart/stores/CartStore";
import {
    cartManagerCounterUpdatersContextToken,
    cartManagerItemsUpdatersContextToken,
    cartQueryToken,
    cartServiceToken,
    cartStoreToken,
} from "@modules/cart/tokens";
import CartListener from "@modules/cart/utils/CartListener/CartListener";
import FetchOrXHRPatcherListenerStrategy from "@modules/cart/utils/CartListener/FetchOrXHRPatcherListenerStrategy";
import SendFormListenerStrategy from "@modules/cart/utils/CartListener/SendFormListenerStrategy";
import CartManagerCounterUpdatersContext from "@modules/cart/utils/CartManager/CartManagerCounterUpdatersContext";
import CartManagerItemsUpdatersContext from "@modules/cart/utils/CartManager/CartManagerItemsUpdatersContext";
import { ProductsModule } from "@modules/products/ProductsModule";
import { TrackingApiService } from "@modules/tracking_events/services/TrackingApiService";
import { lastValueFrom } from "rxjs";
import { container, instanceCachingFactory } from "tsyringe";

import { ModalManager } from "@components/Modal/ModalManager";

import CheckService from "./apps/login/services/CheckService/CheckService";
import { App } from "./constants";
import {
    CURRENT_APP_GROWAVE,
    CURRENT_APP_QUERY_PARAMETER,
} from "./constants/current_app";
import { GP_GW_REFFERER } from "./constants/get_params";
import { CurrentTokenChecker } from "./CurrentTokenChecker";
import { MinilogLogger } from "./lib/Logger";
import MetricsCollectorService from "./services/MetricsCollectorApiService";
import RefreshTokenService from "./services/RefreshTokenService";
import TranslationsApiService from "./services/TranslationsApiService/TranslationsApiService";
import {
    apiClientToken,
    cartListenerToken,
    commonAuthApiClientToken,
    currencyProviderToken,
    eventsCollectorAuthApiClientToken,
    eventsCollectorToken,
    fetchAndXhrPatcherToken,
    fileUploaderAuthApiClientToken,
    globalLoggerToken,
    growaveFeaturesToken,
    gwI18nToken,
    gwStorefrontAppInfoToken,
    htmlSanitizerToken,
    localStorageToken,
    localizationInfoProviderToken,
    locationControllerToken,
    modalManagerToken,
    moneyExchangerToken,
    moneyFormatterToken,
    phpAuthApiClientToken,
    referrerProviderToken,
    sharedTokens,
    shopifyUrlUtilsToken,
    socialLoginApiClientToken,
    tokenManagerToken,
    trackingApiServiceToken,
    translationsApiServiceToken,
    translationsAuthApiClientToken,
} from "./tokens";
import { ShopifyUrlUtils } from "./utils/shopify/ShopifyUrlUtils";

async function __gwMain() {
    if (typeof window !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (window as any).__GW_CONTAINER__ = container;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (window as any).__GW_TOKENS__ = sharedTokens;
    }

    // logger
    const globalLogger = new MinilogLogger("global");
    globalLogger.info("Growave main script is initialized!");
    container.registerInstance(globalLoggerToken, globalLogger);

    container.register(gwStorefrontAppInfoToken, {
        useFactory: instanceCachingFactory(() => {
            return {
                appName: __APP_NAME__,
                version: __VERSION__,
                inIframe: window.top !== window.self,
                designMode: !!Shopify.designMode,
                shopName: Shopify.shop,
                currentApp: CURRENT_APP_GROWAVE,
                // INFO: Key for design mode is better to change, because in design mode customerId(from liquid object) is undefined... its mean that we are logouted
                tokenStorageKey: Shopify.designMode
                    ? "GW_TOKEN__DESIGN_MODE"
                    : "GW_TOKEN",
                appProxyPrefix: "/apps/ssw",
            };
        }),
    });

    akitaDevtools();

    // (utils) customerInfoProvider
    const customerInfoProvider = new CustomerInfoProvider(globalLogger);
    customerInfoProvider.readFromPage();
    container.registerInstance(CustomerInfoProvider, customerInfoProvider);

    // (utils) location controller
    const locationController = new LocationController(window.location);
    container.registerInstance(locationControllerToken, locationController);

    const shopifyUrlUtils = new ShopifyUrlUtils(
        window.Shopify?.routes?.root || "/"
    );
    container.registerInstance(shopifyUrlUtilsToken, shopifyUrlUtils);

    container.register(fileUploaderAuthApiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            return new AuthApiClient(
                new ApiClientWithMetrics(
                    new ApiClient(
                        `${
                            di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                        }/storefront-api/file-uploader-storefront/v2`,
                        undefined,
                        {
                            [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                                gwStorefrontAppInfoToken
                            ).currentApp,
                        },
                        di.resolve(globalLoggerToken)
                    ),
                    di.resolve(eventsCollectorToken),
                    di.resolve(locationControllerToken),
                    di.resolve(AnalyticsEventsFactory)
                ),
                di.resolve(tokenManagerToken)
            );
        }),
    });

    container.register(socialLoginApiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            const customerId = customerInfoProvider.getId();
            return new ApiClientWithMetrics(
                new ApiClient(
                    `${
                        di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                    }/storefront-api/social-login-app/v2`,
                    undefined,
                    {
                        [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                            gwStorefrontAppInfoToken
                        ).currentApp,
                        ...(customerId
                            ? {
                                  logged_in_customer_id: customerId,
                              }
                            : {}),
                    },
                    di.resolve(globalLoggerToken)
                ),
                di.resolve(eventsCollectorToken),
                di.resolve(locationControllerToken),
                di.resolve(AnalyticsEventsFactory)
            );
        }),
    });

    container.register(eventsCollectorAuthApiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            return new AuthApiClient(
                new ApiClient(
                    `${
                        di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                    }/events-collector-service/v2/`,
                    undefined,
                    {
                        [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                            gwStorefrontAppInfoToken
                        ).currentApp,
                    },
                    di.resolve(globalLoggerToken)
                ),
                di.resolve(tokenManagerToken)
            );
        }),
    });

    container.register(commonAuthApiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            return new AuthApiClient(
                new ApiClientWithMetrics(
                    new ApiClient(
                        `${
                            di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                        }/storefront-api/common-storefront/v2`,
                        undefined,
                        {
                            [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                                gwStorefrontAppInfoToken
                            ).currentApp,
                        },
                        di.resolve(globalLoggerToken)
                    ),
                    di.resolve(eventsCollectorToken),
                    di.resolve(locationControllerToken),
                    di.resolve(AnalyticsEventsFactory)
                ),
                di.resolve(tokenManagerToken)
            );
        }),
    });

    container.register(translationsAuthApiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            return new AuthApiClient(
                new ApiClientWithMetrics(
                    new ApiClient(
                        `${
                            di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                        }/translations-service/v2`,
                        undefined,
                        {
                            [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                                gwStorefrontAppInfoToken
                            ).currentApp,
                        },
                        di.resolve(globalLoggerToken)
                    ),
                    di.resolve(eventsCollectorToken),
                    di.resolve(locationControllerToken),
                    di.resolve(AnalyticsEventsFactory)
                ),
                di.resolve(tokenManagerToken)
            );
        }),
    });

    container.register(apiClientToken, {
        useFactory: instanceCachingFactory(() => {
            return new ApiClient();
        }),
    });

    container.register(phpAuthApiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            return new AuthApiClient(
                new ApiClientWithMetrics(
                    new ApiClient(
                        `${
                            di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                        }/storefront-api/v2/`,
                        undefined,
                        {
                            [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                                gwStorefrontAppInfoToken
                            ).currentApp,
                        },
                        di.resolve(globalLoggerToken)
                    ),
                    di.resolve(eventsCollectorToken),
                    di.resolve(locationControllerToken),
                    di.resolve(AnalyticsEventsFactory)
                ),
                di.resolve(tokenManagerToken)
            );
        }),
    });

    // (utils) EventsCollector
    const eventsFactory = new AnalyticsEventsFactory(
        window.performance,
        navigator.userAgent,
        location.href,
        __APP_NAME__
    );
    const eventsCollector = new RxJSAnalyticsEventsCollector();
    container.registerInstance(eventsCollectorToken, eventsCollector);
    container.registerInstance(AnalyticsEventsFactory, eventsFactory);

    // localStorage
    container.register(localStorageToken, { useValue: localStorage });

    // token manager
    container.register(tokenManagerToken, {
        useFactory: instanceCachingFactory((di) => {
            return new GrowaveTokenManager(
                new RefreshTokenService(
                    di.resolve(socialLoginApiClientToken),
                    globalLogger
                ),
                di.resolve(localStorageToken),
                di.resolve(gwStorefrontAppInfoToken).tokenStorageKey,
                window.gw.browserTimeDiffMs,
                globalLogger
            );
        }),
    });

    const metricsCollectorService = new MetricsCollectorService(
        container.resolve(eventsCollectorAuthApiClientToken),
        globalLogger
    );

    const eventsSender = new AnalyticsEventsSender(
        eventsCollector,
        metricsCollectorService
    );
    eventsSender.start();

    // UTILS

    // (utils) historyPatcher
    const historyPatcher = new HistoryPatcher(
        [GP_GW_REFFERER],
        window.history,
        locationController
    );
    historyPatcher.patch();

    // (utils) fetchAndXHRPatcher
    const fetchAndXHRPatcher = new FetchAndXHRPatcher(globalLogger);
    fetchAndXHRPatcher.patch();
    container.registerInstance(fetchAndXhrPatcherToken, fetchAndXHRPatcher);

    // (utils) referrerProvider
    const referrerProvider = new ReferrerProvider();
    container.registerInstance(referrerProviderToken, referrerProvider);

    // (utils) brandingProvider
    const brandingProvider = new BrandingProvider(globalLogger);
    container.registerInstance(BrandingProvider, brandingProvider);

    container.resolve(CurrentTokenChecker).check();

    // (uitls) checkService
    const checkService = new CheckService(
        container.resolve(socialLoginApiClientToken),
        container.resolve(tokenManagerToken),
        globalLogger
    );

    const jwtToken$ = checkService.check().pipe();
    const jwtTokenPromise = lastValueFrom(jwtToken$);

    // (utils) legacyAdapter
    new LegacyAdapter(jwtTokenPromise, container.resolve(tokenManagerToken));

    await jwtTokenPromise;

    // (utils) cartManager
    container.register(cartManagerCounterUpdatersContextToken, {
        useFactory: instanceCachingFactory(() => {
            return new CartManagerCounterUpdatersContext();
        }),
    });

    container.register(cartManagerItemsUpdatersContextToken, {
        useFactory: instanceCachingFactory(() => {
            return new CartManagerItemsUpdatersContext();
        }),
    });

    container.register(cartStoreToken, {
        useFactory: instanceCachingFactory(() => new CartStore()),
    });

    container.register(cartServiceToken, {
        useFactory: instanceCachingFactory((di) => {
            return new CartService(
                new ShopifyCartApiService(
                    new ApiClient("/"),
                    di.resolve(globalLoggerToken)
                ),
                di.resolve(globalLoggerToken),
                di.resolve(cartManagerCounterUpdatersContextToken),
                di.resolve(cartManagerItemsUpdatersContextToken),
                di.resolve(cartStoreToken)
            );
        }),
    });

    container.register(cartQueryToken, {
        useFactory: instanceCachingFactory((di) => {
            return new CartQuery(di.resolve(cartStoreToken));
        }),
    });

    // (utils) cartListener
    const cartListener = new CartListener(globalLogger);
    cartListener.register(new SendFormListenerStrategy(globalLogger));
    cartListener.register(
        new FetchOrXHRPatcherListenerStrategy(globalLogger, fetchAndXHRPatcher)
    );
    container.registerInstance(cartListenerToken, cartListener);

    // (utils) currencyProvider
    const currencyProvider = new FromShopifyCurrencyCurrencyProvider(
        globalLogger
    );
    container.registerInstance(currencyProviderToken, currencyProvider);

    // (utils) featuresProvider
    const featuresProvider = new GrowaveFeaturesProvider(globalLogger);
    const features = featuresProvider.getFeatures();
    container.registerInstance(GrowaveFeaturesProvider, featuresProvider);
    container.registerInstance(growaveFeaturesToken, features);

    container.register(localizationInfoProviderToken, {
        useFactory: instanceCachingFactory(() => {
            return new SimpleLocalizationInfoProvider(
                features.countryIsoCode,
                features.languageIsoCode,
                features.currencyIsoCode,
                features.gwI18nLocale
            );
        }),
    });

    // (utils) TranslationsApiService
    const translationsApiService = new TranslationsApiService(
        container.resolve(translationsAuthApiClientToken),
        globalLogger
    );
    container.registerInstance(
        translationsApiServiceToken,
        translationsApiService
    );

    // (utils) I18n
    const i18n = new GwI18n(globalLogger, eventsCollector);
    const localizationInfoProvider = container.resolve(
        localizationInfoProviderToken
    );

    i18n.registerLoader(
        "common.MediaUploader",
        new I18nApiLoader(
            "common.MediaUploader",
            localizationInfoProvider.getGwI18nLocale(),
            translationsApiService
        )
    );
    i18n.registerLoader(
        "common.PostModal",
        new I18nApiLoader(
            "common.PostModal",
            localizationInfoProvider.getGwI18nLocale(),
            translationsApiService
        )
    );
    container.registerInstance(gwI18nToken, i18n);

    // (utils) moneyFormatter
    container.register(moneyFormatterToken, {
        useFactory: instanceCachingFactory(() => {
            return new MoneyFormatter(
                features.moneyFormat,
                features.moneyWithCurrencyFormat
            );
        }),
    });

    // (utils) GwCurrency
    container.register(GwCurrency, {
        useFactory: instanceCachingFactory(() => {
            const gwCurrency = new GwCurrency(globalLogger, new ApiClient());
            gwCurrency.loadRates();
            return gwCurrency;
        }),
    });

    // (utils) moneyExchanger
    container.register(moneyExchangerToken, {
        useFactory: instanceCachingFactory((di) => {
            if (features.storefrontApiAccessToken) {
                return new FakeMoneyExchanger();
            }
            return new LegacyMoneyExchanger(
                features.shopCurrency,
                di.resolve(GwCurrency)
            );
        }),
    });

    // (utils) HTMLSanitizer
    const HTMLSanitizer = new XSSHTMLSanitizer(globalLogger, {
        span: ["class", "id"],
        div: ["class", "id"],
        p: ["class", "id"],
        i: ["class", "id"],
        b: ["class", "id"],
        pre: ["class", "id"],
    });
    container.registerInstance(htmlSanitizerToken, HTMLSanitizer);

    // (utils) TrackingApiService
    const trackingApiService = new TrackingApiService(
        container.resolve(phpAuthApiClientToken),
        globalLogger
    );
    container.registerInstance(trackingApiServiceToken, trackingApiService);

    // (utils ) ScrollManager
    const scrollManager = new ScrollManager(globalLogger);
    container.registerInstance(ScrollManager, scrollManager);

    // ModalManager
    const modalManager = new ModalManager();
    container.registerInstance(modalManagerToken, modalManager);

    // (utils) GraphQLApiClient
    container.register(GraphQLApiClient, {
        useFactory: instanceCachingFactory(() => {
            if (features.storefrontApiAccessToken) {
                return new GraphQLApiClient(features.storefrontApiAccessToken);
            }
            return null;
        }),
    });

    // (utils)RewardsPopUpWidgetLauncherManager
    const rewardsPopUpWidgetLauncherManager =
        new RewardsPopUpWidgetLauncherManager();
    container.registerInstance(
        RewardsPopUpWidgetLauncherManager,
        rewardsPopUpWidgetLauncherManager
    );

    // (utils)customizationManager
    const customizationManager = new CustomizationManager(globalLogger);
    container.registerInstance(CustomizationManager, customizationManager);

    const productsModule = new ProductsModule();
    productsModule.registerProviders(container);

    const productModule = new ProductModule();
    productModule.registerProviders(container);

    eventsCollector.pushEvent(eventsFactory.createChunkLoaded("main", -1));

    if (features.availableApps.includes(App.Instagram)) {
        container.register<InstagramApplicationConfigInterface>(
            InstagramApplicationConfigToken,
            {
                useValue: {
                    placeholderSelector: ".gw-instagram-gallery-placeholder",
                },
            }
        );
        const startLoadingInstagramApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "InstagramApplication"*/ "@apps/instagram/InstagramApplication"
        ).then(({ InstagramApplication }) => {
            const duration = startLoadingInstagramApplicationTimer.ready();
            eventsCollector.pushEvent(
                eventsFactory.createChunkLoaded(
                    "InstagramApplication",
                    duration
                )
            );
            const instagramApplication =
                container.resolve(InstagramApplication);
            instagramApplication.init();
        });
    }

    if (features.availableApps.includes(App.Login)) {
        const startLoadingLoginApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "LoginApplication"*/ "@apps/login/LoginApplication"
        ).then(({ LoginApplication }) => {
            const duration = startLoadingLoginApplicationTimer.ready();
            eventsCollector.pushEvent(
                eventsFactory.createChunkLoaded("LoginApplication", duration)
            );
            //Temporarily disabling the widget

            // const loginApplication = container.resolve(LoginApplication);
            // loginApplication.init();
        });
    }

    if (features.availableApps.includes(App.TikTok)) {
        container.register<TikTokApplicationConfigInterface>(
            TikTokApplicationConfigToken,
            {
                useValue: {
                    placeholderSelector: "#gw-tiktok-gallery-placeholder",
                },
            }
        );
        void import(
            /*webpackChunkName: "TikTokApplication" */ "@apps/tiktok/TikTokApplication"
        ).then(({ TikTokApplication }) => {
            const tiktokApplication = container.resolve(TikTokApplication);
            tiktokApplication.init();
        });
    }

    if (features.availableApps.includes(App.Wishlist)) {
        container.register<WishlistApplicationConfigInterface>(
            WishlistApplicationConfigToken,
            {
                useValue: {
                    drawerWidgetPlaceholderSelector: "body",
                    addToWishlistProductPageButtonSelector:
                        ".gw-wl-add-to-wishlist-placeholder",
                    addToWishlistProductCardSelector:
                        ".gw-add-to-wishlist-product-card-placeholder",
                    goToWishlistPageHeaderWidgetInjector:
                        ".gw-wl-header-icon-placeholder",
                    wishlistPageWidgetPlaceholderSelector:
                        ".gw-wl-page-placeholder",
                    sharedWishlistPageWidgetPlaceholderSelector:
                        ".gw-wl-shared-page-placeholder",
                    saveWishlistModalWidgetPlaceholderSelector: "body",
                },
            }
        );

        const startLoadingWishlistApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "WishlistApplication" */ "@apps/wishlist/WishlistApplication"
        ).then(({ WishlistApplication }) => {
            const duration = startLoadingWishlistApplicationTimer.ready();
            eventsCollector.pushEvent(
                eventsFactory.createChunkLoaded("WishlistApplication", duration)
            );
            const wishlistApplication = container.resolve(WishlistApplication);
            wishlistApplication.init();
        });
    }

    if (
        features.availableApps.includes(App.Reviews) ||
        features.availableApps.includes(App.Questions)
    ) {
        // reviews
        container.registerInstance<ReviewsApplicationConfigInterface>(
            ReviewsApplicationConfigToken,
            {
                drawerWidgetPlaceholderSelector: "body",
                averageWidgetPlaceholderSelector: ".gw-rv-average-placeholder",
                listingAverageReviewsWidgetPlaceholderSelector:
                    ".gw-rv-listing-average-placeholder",
                reviewsProductPageWidgetPlaceholderSelector:
                    ".gw-rv-main-widget-placeholder",
                reviewsPageWidgetPlaceholderSelector:
                    ".gw-rv-reviews-page-widget-placeholder",
                leaveReviewPageWidgetPlaceholderSelector:
                    ".gw-rv-leave-review-page-widget-placeholder",
                reviewsSliderWidgetPlaceholderSelector:
                    ".gw-rv-reviews-slider-widget-placeholder",
                reviewsBadgeWidgetPlaceholderSelector:
                    ".gw-rv-reviews-trust-badge-placeholder",
                reviewsGalleryWidgetPlaceholderSelector: "body",
                productReviewsMiniSliderWidgetPlaceholderSelector:
                    ".gw-rv-mini-slider-widget-placeholder",
                reviewsFormModalWidgetPlaceholderSelector: "body",
                questionsFormModalWidgetPlaceholderSelector: "body",
            }
        );

        const startLoadingReviewsApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "ReviewsApplication" */ "@apps/reviews/ReviewsApplication"
        ).then(({ ReviewsApplication }) => {
            const duration = startLoadingReviewsApplicationTimer.ready();
            eventsCollector.pushEvent(
                eventsFactory.createChunkLoaded("ReviewsApplication", duration)
            );
            const reviewsApplication = container.resolve(ReviewsApplication);
            reviewsApplication.init();
        });
    }

    // eslint-disable-next-line no-constant-condition
    if (true || features.availableApps.includes(App.IloomCustomization)) {
        container.register<IloomCustomizationApplicationConfigInterface>(
            IloomCustomizationApplicationConfigToken,
            {
                useValue: {
                    designFeedPageWidgetPlaceholderSelector:
                        ".gw-df-page-placeholder",
                    designFeedHomeWidgetPlaceholderSelector:
                        ".gw-df-home-placeholder",
                    designFeedProductWidgetPlaceholderSelector:
                        ".gw-df-product-placeholder",
                    wishlistPageWidgetPlaceholderSelector:
                        ".gw-cwl-page-placeholder",
                    reviewsPageWidgetPlaceholderSelector:
                        ".gw-crv-page-placeholder",
                    reviewsFormModalWidgetPlaceholderSelector:
                        ".gw-crv-form-modal-widget-placeholder",
                },
            }
        );

        const startLoadingIloomCustomizationApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "IloomCustomizationApplication" */ "@apps/iloom_customization/IloomCustomizationApplication"
        ).then(({ IloomCustomizationApplication }) => {
            const duration =
                startLoadingIloomCustomizationApplicationTimer.ready();
            eventsCollector.pushEvent(
                eventsFactory.createChunkLoaded(
                    "IloomCustomizationApplication",
                    duration
                )
            );
            const iloomCustomizationApplication = container.resolve(
                IloomCustomizationApplication
            );
            iloomCustomizationApplication.init();
        });
    }

    if (features.availableApps.includes(App.Rewards)) {
        container.register<RewardsApplicationConfigInterface>(
            RewardsApplicationConfigToken,
            {
                useValue: {
                    rewardsPopUpWidgetWrapperPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsPopUpWidgetWrapperPlaceholder,
                    rewardsJoinNowModalWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsJoinNowModalWidgetPlaceholder,
                    rewardsVerifyAccountModalWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsVerifyAccountModalWidgetPlaceholder,
                    rewardsWelcomeBannerPageWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsWelcomeBannerPageWidgetPlaceholder,
                    rewardsHowItWorksPageWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsHowItWorksPageWidgetPlaceholder,
                    rewardsWaysToEarnPageWidgetPlaceholder:
                        RewardsWidgetsPlaceholders.rewardsWaysToEarnPageWidgetPlaceholder,
                },
            }
        );

        const startLoadingRewardsApplicationTimer = new Timer();
        void import(
            /*webpackChunkName: "RewardsApplication"*/ "@apps/rewards/RewardsApplication"
        ).then(({ RewardsApplication }) => {
            const duration = startLoadingRewardsApplicationTimer.ready();
            eventsCollector.pushEvent(
                eventsFactory.createChunkLoaded("RewardsApplication", duration)
            );
            const rewardsApplication = container.resolve(RewardsApplication);
            rewardsApplication.init();
        });
    }
}

void __gwMain();
